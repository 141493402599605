import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-date-time")
@Component({
    selector: "s25-ng-editable-date-time",
    template: `
        <div *ngIf="this.isInit">
            <s25-ng-editable-date
                [val]="val"
                (valChange)="onDateChange($event)"
                [inputId]="dateInputId"
                [allowEmpty]="allowEmpty"
                [alwaysEditing]="alwaysEditing"
            ></s25-ng-editable-date>
            <s25-timepicker
                [modelValue]="val"
                [defaultZero]="true"
                (modelValueChange)="onTimeChange($event)"
                [class.hidden]="!val"
            ></s25-timepicker>
        </div>
    `,
    styles: `
        .editable-dp {
            display: flex;
        }
        .edit-icon {
            position: relative;
            left: -35px;
            vertical-align: middle;
        }

        .hidden {
            visibility: hidden;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableDateTimeComponent extends S25EditableAbstract implements OnInit, OnChanges {
    @Input() outputString: boolean = false;
    @Input() dateInputId?: string;

    isInit = false;

    getType = () => "date";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.val) {
            if (typeof this.val === "string") this.val = new Date(this.val);
        }
    }

    ngOnInit() {
        if (!this.val && !this.allowEmpty) setTimeout(() => this.setDate(new Date())); // Timeout to avoid changing after change detection but before view updates (in parent)
        this.isInit = true;
        this.cd.detectChanges();
    }

    onDateChange(newDate: Date) {
        if (!newDate) return;
        setTimeout(() => this.setDate(this.val ? S25Util.date.copyTime(newDate, this.val) : newDate)); // Keep time when date changes
    }

    onTimeChange(newTime: Date) {
        if (!newTime) return;
        setTimeout(() =>
            this.setDate(this.val ? S25Util.date.copyTime(S25Util.date.clone(this.val), newTime) : newTime),
        ); // Keep date when time changes
    }

    setDate(newDate: Date) {
        this.val = newDate;
        this.emit();
        this.cd.detectChanges();
    }

    emit() {
        if (!this.outputString) this.valChange.emit(this.val);
        else this.valChange.emit(this.val.toISOString());
    }
}
