//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-text")
@Component({
    selector: "s25-ng-editable-text",
    template: `
        <span *ngIf="this.readOnly">{{ this.val }}</span>

        <div *ngIf="!this.readOnly">
            <div
                *ngIf="!this.editing"
                class="s25-editable-shared-container c-editable-text editable editable-click"
                (click)="this.editMode()"
                (keyup.enter)="this.editMode()"
                (keyup.space)="this.editMode()"
                tabindex="0"
            >
                <div class="s25-editable s25-data">{{ this.val }}</div>
            </div>

            <div *ngIf="this.editing">
                <input
                    class="firstFocusEditable c-input"
                    type="text"
                    [id]="this.fieldID"
                    [minlength]="this.min"
                    [maxlength]="this.max"
                    [disabled]="this.disabled"
                    [ngModel]="this.candidateVal"
                    (ngModelChange)="this.update($event)"
                    (blur)="this.onInputBlur()"
                    (keyup.enter)="this.submit()"
                />

                <div class="aw-button-group">
                    <button
                        class="aw-button aw-button--outline"
                        *ngIf="this.hasCancelButton"
                        (click)="this.cancel()"
                        (keyup.enter)="this.cancel()"
                    >
                        {{ this.cancelButtonText }}
                    </button>
                    <button
                        class="aw-button aw-button--primary"
                        *ngIf="this.hasCommitButton"
                        (click)="this.commit()"
                        (keyup.enter)="this.commit()"
                        [disabled]="committing"
                        [class.is-committing]="committing"
                    >
                        {{ committing ? "" : commitButtonText }}
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                    </button>
                </div>

                <div *ngIf="this.errorMessages">
                    <div *ngFor="let msg of this.errorMessages" class="ngRed ngBold">
                        {{ msg }}
                    </div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableTextComponent extends S25EditableAbstract implements OnInit, OnChanges {
    getType = () => "text";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges() {
        this.valChange.emit(this.val);
    }
}
