//@author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25ImageService } from "../../s25-image/s25.image.service";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { ModalService } from "../../modal/modal.service";
import { ImageModelI, S25ImageComponent } from "../../s25-image/s25.image.component";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ImageService } from "../../../services/image.service";
import { ModalImageI } from "../../modal/modals/modal.image.upload.component";
import { ImageActionDataI } from "../../s25-image-upload/s25.image.upload.component";
import { FlsService } from "../../../services/fls.service";

declare var angular: any;

@TypeManagerDecorator("s25-ng-editable-image")
@Component({
    selector: "s25-ng-editable-image",
    template: ` <div *ngIf="init" class="editable-image" [ngClass]="{ 'cust-attr-img': custAttrType }">
        <a
            *ngIf="!readOnly"
            class="c-evdd-image-upload"
            (click)="openModal()"
            (keyup.enter)="openModal()"
            tabindex="0"
            >{{ custAttrType ? "Select or Upload Image" : "Edit" }}</a
        >

        <s25-popover
            *ngIf="!displayImage && (val || model.imageUrl)"
            [modelBean]="{ popoverTemplate: imagePreview }"
            [openTrigger]="'click'"
            [closeTrigger]="'click'"
        >
            <button class="aw-button aw-button--outline">Preview Image</button>
        </s25-popover>

        <ng-template #imagePreview>
            <s25-ng-image [model]="model"></s25-ng-image>
        </ng-template>

        <s25-ng-image *ngIf="displayImage" [model]="model"></s25-ng-image>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableImageComponent extends S25EditableAbstract implements OnInit {
    @Input() showImage: boolean = true;
    @Input() width: number;
    @Input() autoSaveUpload: boolean;
    @Input() onCommit?: (inputVal: any) => any; //defined in editable abstract
    @Input() val: number; //defined in editable abstract
    @Input() imageTypeName?: "Layout Diagram" | "Layout Image" | "Image" | "Diagram";
    @Input() custAttrType?: "EventImage" | "DetailImage";
    @Input() url?: string;

    model: ImageModelI;
    modalModel: ModalImageI;
    init = false;
    displayImage = true;
    hasUploadPerms: boolean;

    getType = () => "image";

    @ViewChild(S25ImageComponent) imageChild: S25ImageComponent;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    setImage = (imageId: number, url: string) => {
        this.model = {
            imageId: imageId,
            title: "",
            imageUrl: url || (imageId && S25ImageService.getUrl(imageId, this.width)),
        };
    };

    ngOnInit() {
        super.ngOnInit();

        FlsService.getFls().then((data) => {
            this.hasUploadPerms = data.SPACE_VIEW === "F";

            this.imageTypeName = S25Util.contains(this.imageTypeName?.toLowerCase(), "diagram") ? "Diagram" : "Image";
            this.setImage(this.val, this.url);
            this.displayImage = this.showImage && (!!this.val || !!this.url);
            let processChosenImage = (imageId: number) => {
                if (imageId) {
                    this.onCommit && this.onCommit({ imageId: imageId });
                    setImageData(imageId);

                    // modify image url for trumba recognition
                    if (this.custAttrType) {
                        return ImageService.getImage(imageId).then((resp) => {
                            const input =
                                this.elementRef.nativeElement.parentElement.parentElement.parentElement.querySelector(
                                    `.${this.custAttrType}-input`,
                                );

                            const imageUrl = S25ImageService.getUrl(imageId, this.width);
                            if (imageUrl) {
                                const prevImageUrl = imageUrl.split("&");

                                if (prevImageUrl.length > 0) {
                                    prevImageUrl[1] = `filename=${resp.file_name}`;
                                    const trumbaUrl = prevImageUrl.slice(0, 2).join("&");

                                    angular
                                        .element(document.body)
                                        .injector()
                                        .get("$rootScope")
                                        .$broadcast(this.custAttrType, trumbaUrl);

                                    if (input) input.value = trumbaUrl;
                                    this.model.imageUrl = trumbaUrl;
                                }
                            }
                        });
                    }
                }
            };

            let setImageData = (imageId: number) => {
                this.modalModel.imageUrl = null;
                this.model.imageId = imageId;
                this.model.imageUrl = null;
                this.model.data = imageId;
                this.val = imageId;
                this.displayImage = this.showImage;
                this.cd.detectChanges();
                this.imageChild && this.imageChild.init(this.model);
                this.modalModel.closeModal();
            };

            let validateName = (modalModel: any) => {
                modalModel.errorMsg = "";
                if (!modalModel.imageModel.imageName) {
                    modalModel.errorMsg = "Please enter a name for the image";
                } else if (modalModel.imageModel.imageName.length > 40) {
                    modalModel.errorMsg = "Image name cannot be longer than 40 characters";
                }
                return !modalModel.errorMsg;
            };

            this.modalModel = {
                onDone: () => {
                    this.modalModel.errorMsg = null;
                    if (this.modalModel.isUpload && this.hasUploadPerms) {
                        if (validateName(this.modalModel)) {
                            this.modalModel.imageModel.getImageData().then((imageData: string) => {
                                if (imageData) {
                                    let actionData: ImageActionDataI = {
                                        imageName: this.modalModel.imageModel.imageName,
                                        imageDesc: this.modalModel.imageModel.imageDesc,
                                        imageTypeId: this.modalModel.imageModel.imageTypeId, // Categorizing as Photograph/Diagram
                                        imageFileName: this.modalModel.imageModel.getImageFileName(),
                                        imageData: S25Util.extractBase64FromURI(imageData),
                                        imageType: this.modalModel.imageModel.getImageType(),
                                    };
                                    if (S25Util.toBool(this.autoSaveUpload)) {
                                        ImageService.postImage(
                                            actionData.imageName,
                                            actionData.imageDesc,
                                            actionData.imageFileName,
                                            actionData.imageData,
                                            actionData.imageTypeId,
                                        ).then((resp) => {
                                            processChosenImage(resp);
                                        });
                                    } else {
                                        this.onCommit(actionData).then(() => {
                                            if (actionData.imageId) {
                                                setImageData(actionData.imageId);
                                            } else {
                                                this.modalModel.imageUrl = null;
                                                this.model.imageId = null;
                                                this.model.imageUrl = null;
                                                this.model.data = actionData.imageData;
                                                this.model.fileType = actionData.imageType;
                                                this.displayImage = true;
                                                this.cd.detectChanges();
                                                this.imageChild && this.imageChild.init(this.model);
                                                this.modalModel.closeModal();
                                            }
                                        });
                                    }
                                } else {
                                    this.modalModel.errorMsg = "Please upload an image";
                                }
                            });
                        }
                    } else {
                        // select mode
                        if (this.modalModel.selectModel.selectedImage) {
                            let imageId = parseInt(this.modalModel.selectModel.selectedImage.image_id);
                            processChosenImage(imageId);
                        } else {
                            this.modalModel.errorMsg = "Please select an image";
                        }
                    }
                },

                imageTypeName: this.imageTypeName,
                hasUploadPerms: this.hasUploadPerms,
            };

            this.init = true;
            this.cd.detectChanges();
        });
    }

    openModal() {
        ModalService.modal("image-upload", this.modalModel);
    }
}
