import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-percentage")
@Component({
    selector: "s25-ng-editable-percentage",
    template: `
        <span *ngIf="this.readOnly">{{ this.val | number: "1.1-3" }}%</span>

        <div *ngIf="!this.readOnly">
            <div
                *ngIf="!this.editing"
                class="s25-editable-shared-container c-editable-text editable editable-click"
                (click)="this.editMode()"
                (keyup.enter)="this.editMode()"
                (keyup.space)="this.editMode()"
            >
                <div class="s25-editable s25-data">{{ this.val | number: "1.1-3" }}%</div>
            </div>

            <div *ngIf="this.editing">
                <input
                    class="firstFocusEditable"
                    type="number"
                    [disabled]="this.disabled"
                    [min]="this.min"
                    [max]="this.max"
                    step="1"
                    [ngModel]="this.candidateVal"
                    (ngModelChange)="this.update($event)"
                    (blur)="this.onInputBlur()"
                    (keyup.enter)="this.submit()"
                />

                <button
                    class="aw-button aw-button--outline"
                    *ngIf="this.hasCancelButton"
                    (click)="this.cancel()"
                    (keyup.enter)="this.cancel()"
                >
                    {{ this.cancelButtonText }}
                </button>
                <button
                    class="aw-button aw-button--primary"
                    *ngIf="this.hasCommitButton"
                    (click)="this.commit()"
                    (keyup.enter)="this.commit()"
                >
                    {{ this.commitButtonText }}
                </button>

                <div *ngIf="this.errorMessages">
                    <div *ngFor="let msg of this.errorMessages" class="ngRed ngBold">
                        {{ msg }}
                    </div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EditablePercentageComponent extends S25EditableAbstract implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    getType = () => "percentage";

    ngOnInit() {
        super.ngOnInit();
    }
}
