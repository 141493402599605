//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-textarea")
@Component({
    selector: "s25-ng-editable-textarea",
    template: `
        <span *ngIf="this.readOnly" [innerHTML]="this.val"></span>

        <div *ngIf="!this.readOnly">
            <div
                *ngIf="!this.editing"
                class="s25-editable-shared-container c-editable-text editable editable-click"
                (click)="this.editMode()"
                (keyup.enter)="this.editMode()"
                (keyup.space)="this.editMode()"
            >
                <div class="s25-editable s25-data" [innerHTML]="this.val"></div>
            </div>

            <div *ngIf="this.editing">
                <textarea
                    class="firstFocusEditable"
                    [minlength]="this.min"
                    [maxlength]="this.max"
                    [disabled]="this.disabled"
                    [ngModel]="this.candidateVal"
                    [rows]="this.rows"
                    [cols]="this.cols"
                    (ngModelChange)="this.update($event)"
                    (blur)="this.onInputBlur()"
                ></textarea>

                <div class="aw-button-group">
                    <button
                        class="aw-button aw-button--outline"
                        *ngIf="this.hasCancelButton"
                        (click)="this.cancel()"
                        (keyup.enter)="this.cancel()"
                    >
                        {{ this.cancelButtonText }}
                    </button>
                    <button
                        class="aw-button aw-button--primary"
                        *ngIf="this.hasCommitButton"
                        (click)="this.commit()"
                        (keyup.enter)="this.commit()"
                    >
                        {{ this.commitButtonText }}
                    </button>
                </div>

                <div *ngIf="this.errorMessages">
                    <div *ngFor="let msg of this.errorMessages" class="ngRed ngBold">
                        {{ msg }}
                    </div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableTextareaComponent extends S25EditableAbstract implements OnInit {
    getType = () => "textarea";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
