//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25EditableAbstract } from "../s25.editable.abstract";

@TypeManagerDecorator("s25-ng-editable-richtext")
@Component({
    selector: "s25-ng-editable-richtext",
    template: `
        <div *ngIf="this.readOnly" [innerHTML]="this.val | safeHTML"></div>

        <div *ngIf="!this.readOnly">
            <div
                class="s25-editable-shared-container c-editable-text editable editable-click"
                (click)="this.editMode()"
                (keyup.enter)="this.editMode()"
                (keyup.space)="this.editMode()"
                tabindex="0"
            >
                <div *ngIf="!this.editing" class="s25-editable s25-data" [innerHTML]="this.val | safeHTML"></div>
            </div>

            <div *ngIf="this.editing">
                <s25-ng-rich-text-editor
                    [modelValue]="this.val"
                    [autoFocus]="true"
                    (onEditorContentChange)="this.update($event)"
                ></s25-ng-rich-text-editor>

                <div class="aw-button-group">
                    <button
                        class="aw-button aw-button--outline"
                        *ngIf="this.hasCancelButton"
                        (click)="this.cancel()"
                        (keyup.enter)="this.cancel()"
                    >
                        {{ this.cancelButtonText }}
                    </button>
                    <button
                        class="aw-button aw-button--primary"
                        *ngIf="this.hasCommitButton"
                        (click)="this.commit()"
                        (keyup.enter)="this.commit()"
                    >
                        {{ this.commitButtonText }}
                    </button>
                </div>

                <div *ngIf="this.errorMessages">
                    <div *ngFor="let msg of this.errorMessages" class="ngRed ngBold">
                        {{ msg }}
                    </div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableRichtextComponent extends S25EditableAbstract implements OnInit, OnChanges {
    getType = () => "richtext";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges() {
        this.valChange.emit(this.val);
    }
}
